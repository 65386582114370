import { useCallback, useEffect, useRef } from 'react';

/**
 * Turn the points returned from perfect-freehand into SVG path data.
 */
export function getSvgPathFromStroke(stroke) {
  if (!stroke.length) return '';
  var d = stroke.reduce((acc, _ref, i, arr) => {
    var [x0, y0] = _ref;
    var [x1, y1] = arr[(i + 1) % arr.length];
    acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
    return acc;
  }, ['M', ...stroke[0], 'Q']);
  d.push('Z');
  return d.join(' ');
}
export var getBoundingClientRect = el => {
  var rect = el == null ? void 0 : el.getBoundingClientRect();
  var offsetX = (rect == null ? void 0 : rect.left) || 0;
  var offsetY = (rect == null ? void 0 : rect.top) || 0;
  return {
    offsetX,
    offsetY
  };
};
export var getClinetXY = _ref2 => {
  var {
    clientX,
    clientY
  } = _ref2;
  return {
    clientX,
    clientY
  };
};
export var defaultStyle = {
  '--w-signature-background': '#fff',
  touchAction: 'none',
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: 'var(--w-signature-background)'
};

// Saves incoming handler to the ref in order to avoid "useCallback hell"
export function useEvent(handler) {
  var callbackRef = useRef(handler);
  useEffect(() => {
    callbackRef.current = handler;
  });
  return useCallback(event => callbackRef.current && callbackRef.current(event), []);
}